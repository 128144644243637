import Vue from "vue";
import VueRouter from "vue-router";
import annualReport from "../views/annualReport.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "annualReport",
    component: annualReport,
    meta: {
      title: "年终报告",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
