const numberToCurrencyNo = function (value) {


  // 将数字转换为字符串
  const strValue = String(value);

  // 检查是否存在小数点
  const decimalIndex = strValue.indexOf(".");

  // 如果没有小数点，则直接添加千分位分隔符并返回
  if (decimalIndex === -1) {
    return strValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  // 存在小数点时，将整数部分添加千分位分隔符，并保留两位小数
  const integerPart = strValue
    .slice(0, decimalIndex)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  const decimalPart = strValue.slice(decimalIndex + 1);

  // 当小数部分全为0时，只保留两位小数
  if (/^0+$/.test(decimalPart)) {
    return integerPart + "." + decimalPart.slice(0, 2);
  }

  // 返回带有千分位分隔符和原始小数部分的结果
  return integerPart + "." + decimalPart;
};


const timedjs = function (value) {
  let times;
  const months =parseInt(value);
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  if(years==0&&remainingMonths==0){
    times='1个月'
  }else if(years==0&&remainingMonths>0){
    times=remainingMonths+'个月'
  }else if(years>0&&remainingMonths==0){
    times=years+'年'
  }else if(years>0&&remainingMonths>0){
    times=years+'年'+remainingMonths+'个月'
  }
  return times;
}

export { numberToCurrencyNo ,timedjs};
