<template>
  <div id="container">
    <div class="content bg1" ref="content">
      <div
        class="orgName wow flipInX"
        data-wow-delay="0.5s"
        data-wow-duration="1.5s"
      >
        {{ info["orgName"] }}
      </div>
      <div class="arrow">
        <img
          src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/02_%E5%B0%81%E9%9D%A2%E7%AE%AD%E5%A4%B4.png"
          alt=""
        />
        <img
          src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/03_%E5%B0%81%E9%9D%A2%E7%AE%AD%E5%A4%B4.png"
          alt=""
        />
        <img
          src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/04_%E5%B0%81%E9%9D%A2%E7%AE%AD%E5%A4%B4.png"
          alt=""
        />
      </div>
    </div>
    <div class="content bg2 mk2">
      <!--class="title1 wow flipInX" -->
      <div
        :class="
          parseInt(info.turnover) > 0
            ? 'title1 wow flipInX'
            : 'title1 wow flipInX moneynull'
        "
        data-wow-delay="0.5s"
        data-wow-duration="1.5s"
      >
        您已与艾摩米合作
      </div>
      <!--class="data1 wow flipInX" -->
      <div
        :class="
          parseInt(info.turnover) > 0
            ? 'data1 wow flipInX'
            : 'data1 wow flipInX moneynull'
        "
        data-wow-delay="1s"
        data-wow-duration="1.5s"
      >
        {{ info["cooperationMonth"] | timedjs }}
      </div>
      <div v-if="parseInt(info.turnover) > 0" class="title2">
        预估创收<span>(截至至12月28日)</span>
      </div>

      <div v-if="parseInt(info.turnover) > 0" class="data2">
        {{ info["turnover"] | numberToCurrencyNo }}元
      </div>
      <div class="title3 wow bounce" data-wow-duration="1.5s">
        <div>感谢您一直以来对艾摩米的关注和支持。</div>
        <div>在过去的一年里，我们一直致力于为您提供最优质的服务和产品。</div>
        <div>我们为您提供2023年的年度物联网报告，</div>
        <div>希望能为您的经营提供更多帮助。</div>
      </div>
    </div>
    <div class="content bg3">
      <div
        class="samllTit wow flipInX"
        data-wow-delay="0.5s"
        data-wow-duration="1.5s"
      >
        开始进入您的
      </div>
      <div
        class="bigTit wow flipInX"
        data-wow-delay="1s"
        data-wow-duration="1.5s"
      >
        年度数据概览
      </div>
      <div class="arrow">
        <img
          src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/07_%E8%BF%9B%E5%85%A5%E9%A1%B5%E7%AE%AD%E5%A4%B4.png"
          alt=""
        /><img
          src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/08_%E8%BF%9B%E5%85%A5%E9%A1%B5%E7%AE%AD%E5%A4%B4.png"
          alt=""
        /><img
          src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/09_%E8%BF%9B%E5%85%A5%E9%A1%B5%E7%AE%AD%E5%A4%B4.png"
          alt=""
        />
      </div>
    </div>
    <!-- 今年门店概况 -->
    <div class="content bg4 jrmdgk">
      <div class="titleDiv">
        <div
          class="bigTitle wow flipInX"
          data-wow-delay="0.5s"
          data-wow-duration="1.5s"
        >
          今年门店概况
        </div>
      </div>

      <div
        class="subheading1 wow flipInX"
        data-wow-delay="1s"
        data-wow-duration="1.5s"
      >
        门店数量<span class="data">{{ this.orgData.shopNum }}</span
        >家
      </div>
      <div
        class="subheading2 wow flipInX"
        data-wow-delay="1.5s"
        data-wow-duration="1.5s"
      >
        员工数量<span class="data">{{ this.orgData.empNum }}</span
        >人
      </div>
      <div
        class="subheading3Div wow flipInX"
        data-wow-delay="1.5s"
        data-wow-duration="1.5s"
      >
        <div>
          单设备门店数量<span class="data" style="font-size: 1.3rem">{{
            this.orgData.singleDevShopNum
          }}</span
          >家
        </div>
        <div style="margin-left: 1.3rem">
          多设备门店数量<span class="data" style="font-size: 1.3rem">{{
            this.orgData.moreDevShopNum
          }}</span
          >家
        </div>
      </div>
      <div
        class="subheading4Div wow flipInX"
        data-wow-delay="2.5s"
        data-wow-duration="1.5s"
      >
        <div>门店 | 员工 | 设备的正确统计是物联网数据分析的基础</div>
        <div>*如对门店 | 员工 | 设备归属等有疑问，请联系客户处理</div>
      </div>
    </div>
    <!-- 设备基本情况只有一台的时候 -->
    <div
      class="content bg5 sbjbqk one"
      v-show="selectDeviceBaseInfo.deviceNum == 1"
    >
      <div
        class="titleDiv wow flipInX"
        data-wow-delay="0.5s"
        data-wow-duration="1.5s"
      >
        <div class="bigTitle">设备基本情况</div>
        <div class="smallTilte">
          截止今年12月总设备数<span>{{ selectDeviceBaseInfo.deviceNum }}</span
          >台
        </div>
      </div>
      <div class="txts">
        <div>
          设备类型：<span>{{
            selectDeviceBaseInfo.yearDeviceTypeInfoList[0].deviceTypeName
          }}</span>
        </div>
        <div>
          启动次数：<span>{{
            selectDeviceBaseInfo.yearDeviceTypeInfoList[0].startUpNum
          }}</span
          >次
        </div>
        <div>
          全国平均启动次数：<span>{{
            selectDeviceBaseInfo.yearDeviceTypeInfoList[0].avgStartUpNum
          }}</span
          >次
        </div>
      </div>

      <div
        class="subheading4Div wow flipInX"
        data-wow-delay="1s"
        data-wow-duration="1.5s"
      >
        <div>您可在小程序-门店板块-设备管理中查看所有设备</div>
        <div>
          *全国平均启动次数：全国所有该设备类型的2023年1月1日-12月28日的平均单台启动次数
        </div>
      </div>
    </div>
    <!-- 设备基本情况 -->
    <div class="content bg5 sbjbqk" v-show="selectDeviceBaseInfo.deviceNum > 1">
      <div
        class="titleDiv wow flipInX"
        data-wow-delay="0.5s"
        data-wow-duration="1.5s"
      >
        <div class="bigTitle">设备基本情况</div>
        <div class="smallTilte">
          截止今年12月总设备数<span>{{ selectDeviceBaseInfo.deviceNum }}</span
          >台
        </div>
      </div>
      <div
        class="deviceTable wow pulse"
        data-wow-delay="1s"
        data-wow-duration="2s"
      >
        <table>
          <thead>
            <tr>
              <th style="width: 33%; text-align: left; padding-left: 2rem">
                设备类型
              </th>
              <th>设备数量</th>
              <th>启动次数</th>
              <th style="width: 20%">平均启动次数</th>
              <th style="width: 20%">全国平均启动次数</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(
                item, index
              ) in selectDeviceBaseInfo.yearDeviceTypeInfoList"
              :key="index"
            >
              <td style="text-align: left; padding-left: 2rem">
                {{ item.deviceTypeName }}
              </td>
              <td>{{ item.deviceNum }}</td>
              <td>{{ item.startUpNum }}</td>
              <td>{{ item.avgStartUpNum }}</td>
              <td style="color: rgb(193, 36, 32)">
                {{ item.avgStartUpNumByCounty }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="subheading4Div wow flipInX"
        data-wow-delay="1s"
        data-wow-duration="1.5s"
        style="top: 88%"
      >
        <div>您可在小程序-门店板块-设备管理中查看所有设备</div>
        <div>
          *全国平均启动次数：全国所有该设备类型的2023年1月1日-12月28日的平均单台启动次数
        </div>
      </div>
    </div>
    <!-- 设备启动次数 -->
    <div class="content bg6">
      <div
        class="titleDiv wow flipInX"
        data-wow-delay="0.5s"
        data-wow-duration="1.5s"
      >
        <div class="bigTitle">设备启动次数</div>
        <div class="smallTilte">
          截止今年12月总启动<span>{{ selectDeviceStartUp.devStartUpNum }}</span
          >次
        </div>
      </div>
      <div class="startNumCanvas">
        <div class="barChart" ref="barChart"></div>
      </div>
      <div
        class="subheading4Div wow flipInX"
        data-wow-delay="1.5s"
        data-wow-duration="1.5s"
      >
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>*设备每月的启动次数分布</div>
      </div>
    </div>
    <!-- 皮肤检测概况 -->
    <div class="content bg7 pfjcgk">
      <div class="titleDiv">
        <div
          class="bigTitle wow flipInX"
          data-wow-delay="0.5s"
          data-wow-duration="1.5s"
        >
          皮肤检测概况
        </div>
      </div>
      <div
        class="subheading1 wow flipInX"
        data-wow-delay="1s"
        data-wow-duration="1.5s"
      >
        顾客累计数量<span class="data">{{ ClientCount }}</span
        >位
      </div>
      <div
        class="subheading2 wow flipInX"
        data-wow-delay="1.5s"
        data-wow-duration="1.5s"
      >
        案例累计数量<span class="data">{{ CasegroupCount }}</span
        >组
      </div>
      <div
        class="subheading3Div wow flipInX"
        data-wow-delay="2s"
        data-wow-duration="1.5s"
      >
        <div>
          平均案例数量<span class="data" style="font-size: 1.3rem">{{
            CasegroupAvgCount
          }}</span
          >组
        </div>
        <div style="margin-left: 1.3rem">
          全国平均案例数量<span class="data" style="font-size: 1.3rem">1.5</span
          >组
        </div>
      </div>
      <div
        class="subheading4Div wow flipInX"
        data-wow-delay="2.5s"
        data-wow-duration="1.5s"
      >
        <div>
          学习面诊课程，可提升员工咨询成交能力与使用频率，后续可制定顾客每次到店<br/>都进行检测的标准
        </div>
        <div>
          据统计，参加了面诊课程的门店，平均案例数提升近40%，意味着复购率提高
        </div>
        <div>*全国平均案例数量：全国平均每个顾客拥有1.5组案例组数</div>
      </div>
    </div>
    <div class="content bg8">
      <div
        class="samllTit wow flipInX"
        data-wow-delay="0.5s"
        data-wow-duration="1.5s"
      >
        即将为您呈现
      </div>
      <div
        class="bigTit wow flipIn"
        data-wow-delay="0.5s"
        data-wow-duration="1.5s"
      >
        设备数据统计
      </div>
      <div class="arrow">
        <img
          src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/02_%E5%B0%81%E9%9D%A2%E7%AE%AD%E5%A4%B4.png"
          alt=""
        />
        <img
          src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/03_%E5%B0%81%E9%9D%A2%E7%AE%AD%E5%A4%B4.png"
          alt=""
        />
        <img
          src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/04_%E5%B0%81%E9%9D%A2%E7%AE%AD%E5%A4%B4.png"
          alt=""
        />
      </div>
    </div>
    <!-- 设备基本情况-红 -->
    <div class="content bg9">
      <div
        class="titleDiv wow flipInX"
        data-wow-delay="0.5s"
        data-wow-duration="1.5s"
      >
        <div class="bigTitle" style="color: #fefaef">设备开机排行</div>
        <div class="smallTilte" style="color: #ffe98d">红榜</div>
      </div>
      <div class="redTable">
        <table>
          <thead>
            <tr>
              <th>设备ID</th>
              <th style="width: 30%">设备类别</th>
              <th style="width: 30%">所属门店</th>
              <th style="width: 15%">开机次数</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in selectDeviceStartUpTopDesc"
              :key="index"
            >
              <td>
                {{ item.deviceid }}
              </td>
              <td>{{ item.deviceTypeName }}</td>
              <td>{{ item.shopName }}</td>
              <td style="font-size: 0.45rem">{{ item.startUpNum }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="tipDiv wow flipInX"
        data-wow-delay="1.5s"
        data-wow-duration="1.5s"
      >
        <div>&nbsp;</div>
        <div style="color: rgba(243, 207, 178, 0.82)">
          *设备的<span style="color: #ffe98d">启动次数排行(前10)，</span
          >如对设备归属有疑问，请联系客服处理
        </div>
      </div>
    </div>
    <!-- 设备开机排行-黑 -->
    <!--只有一台设备的时候，隐藏-->
    <div
      class="content bg10 sbkjphb"
      v-show="selectDeviceBaseInfo.deviceNum > 1 ? 1 : 0"
    >
      <div class="titleDiv">
        <div class="bigTitle" style="color: #fefaef">设备开机排行</div>
        <div class="smallTilte" style="color: #ffe98d">黑榜</div>
      </div>

      <div class="redTable">
        <table>
          <thead style="background-color: #595959; color: #fefaef">
            <tr>
              <th>设备ID</th>
              <th style="width: 30%">设备类别</th>
              <th style="width: 30%">所属门店</th>
              <th style="width: 15%">开机次数</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in selectDeviceStartUpTopAsc" :key="index">
              <td>
                {{ item.deviceid }}
              </td>
              <td>{{ item.deviceTypeName }}</td>
              <td>{{ item.shopName }}</td>
              <td style="font-size: 0.45rem">{{ item.startUpNum }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="tipDiv wow flipInX"
        data-wow-delay="1.5s"
        data-wow-duration="1.5s"
        style="color: rgba(243, 207, 178, 0.82)"
      >
        <div>
          设备启动少，可能原因是培训少、对设备品项不了解、设备闲置等。门店可通过<br/>
          <span style="text-decoration: underline">小程序直播</span>学习
        </div>
        <div>
          设备知识，或<span style="text-decoration: underline"
            >申请下店培训</span
          >。
        </div>
        <div>
          *设备的<span style="color: #ffe98d">启动次数排行(前10)，</span
          >如对设备归属有疑问，请联系客服处理
        </div>
      </div>
    </div>
    <!-- 门店开机情况 -->
    <!--只有一台设备的时候，隐藏-->
    <div
      class="content bg10 mdkjphb"
      v-show="selectDeviceBaseInfo.deviceNum > 1 ? 1 : 0"
    >
      <div class="titleDiv">
        <div class="bigTitle" style="color: #fefaef">门店开机情况</div>
      </div>
      <div class="tableDiv">
        <div class="redTable" style="width: 68%">
          <table
            style="width: 32.5%"
            v-for="(item, index) in ShopStartUpTop"
            :key="index"
          >
            <thead>
              <tr>
                <th style="width: 75%">{{ item.deviceTypeName }}</th>
                <th>开机次数</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(lititem, index2) in item.deviceStartUpTopDTOList"
                :key="index2"
              >
                <td style="">
                  {{ lititem.shopName }}
                </td>
                <td>{{ lititem.startUpNum }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="tipDiv wow flipInX"
        data-wow-delay="1.5s"
        data-wow-duration="1.5s"
        style="color: rgba(243, 207, 178, 0.82); top: 90%"
      >
        <div>
          合作面龄医生的门店平均 单店单月
          开机148次（无检测不护肤，适合需要升级转型的门店）
        </div>
        <div>
          *启动次数<span style="color: #ffe98d">(前3)</span>的设备种类的启动情况
        </div>
      </div>
    </div>
    <!-- 员工操作排行 -->
    <div class="content bg11 ygccph">
      <div
        class="titleDiv wow flipInX"
        data-wow-delay="0.5s"
        data-wow-duration="1.5s"
      >
        <div class="bigTitle" style="color: #fefaef">员工操作排行</div>
      </div>
      <div class="redTable">
        <table>
          <thead>
            <tr>
              <th style="width: 30%">员工姓名</th>
              <th>所属门店</th>
              <th style="width: 15%">操作次数</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in selectEmpOperateTop" :key="index">
              <td>
                {{ item.empName == null ? "-" : item.empName }}
              </td>
              <td>{{ item.shopName }}</td>
              <td>{{ item.startUpNum }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="tipDiv wow flipInX"
        data-wow-delay="1.5s"
        data-wow-duration="1.5s"
        style="color: rgba(243, 207, 178, 0.82); top: 88%"
      >
        <div>
          一个优秀的员工除了熟练的技术外，还需专业知识的加持，才能更好留住顾客。<br />可关注商学院板块的课程安排，让员工参与学习。
        </div>
        <div>
          *员工的启动次数排行<span style="color: #ffe98d">(前10)，</span
          >同一员工操作不同门店的设备分别统计操作次数
        </div>
      </div>
    </div>
    <!-- 无数据页-艾摩米总结 -->
    <div class="content bg12 notData" v-show="isGdDevice == 0 ? 1 : 0">
      <div class="divName">
        <div>设备耗材使用排行</div>
        <div>门店耗材使用排行</div>
      </div>
      <div class="conDiv">
        <div style="font-size: 2rem">在该板块，艾摩米总结了</div>
        <div>光电设备的耗材使用情况，及门店的耗材使用统计</div>
      </div>
      <div class="conDiv2">
        <div>
          小程序更新预告：
          新版数据报告，将为您提供全国耗材使用统计与市场趋势分析，
        </div>
        <div style="color: #ffe98d">
          方便您做2024年店内耗材采购计划、品项推广计划
        </div>
      </div>
      <div
        class="subheading4Div wow flipInX"
        data-wow-delay="1.5s"
        data-wow-duration="1.5s"
        style="color: rgba(243, 207, 178, 0.82)"
      >
        <div>
          *您尚未与艾摩米合作光电设备项目，所以暂无耗材数据可查看，如您有广电设备，
        </div>
        <div>可能是设备归属问题，请联系客服处理</div>
      </div>
    </div>
    <!-- 设备耗材使用排行 v-show="isGdDevice==1?1:0"-->
    <div class="content bg12 sbhcsyph"  v-show="isGdDevice==1?1:0">
      <div class="titleDiv">
        <div class="bigTitle" style="color: #fefaef">设备耗材使用排行</div>
      </div>
      <div class="tableDiv">
        <div class="redTable" style="width: 68%">
          <table
            style="width: 32.5%"
            v-for="(item, index) in selectConUseInfo"
            :key="index"
          >
            <thead>
              <tr>
                <th style="width: 75%">
                  {{
                    item.deviceStartUpTopDTOList[0].deviceTypeName == null ||
                    item.deviceStartUpTopDTOList[0].deviceTypeName == underfind
                      ? "-"
                      : item.deviceStartUpTopDTOList[0].deviceTypeName
                  }}
                </th>
                <th>消耗次数</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(lititem, index2) in item.deviceStartUpTopDTOList"
                :key="index2"
              >
                <td style="">
                  {{ lititem.consumableTypeName }}
                </td>
                <td>{{ lititem.startUpNum }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="tipDiv2">
        <div>
          帮您找到热门耗材
        </div>
        <div>
          小程序更新预告：
          新版数据报告，将为您提供全国耗材使用统计与市场趋势分析，
        </div>
        <div>方便您做2024年店内耗材采购计划、品项推广计划</div>
      </div>
      <div
        class="tipDiv wow flipInX"
        data-wow-delay="1.5s"
        data-wow-duration="1.5s"
      >
        <div>&nbsp;</div>
        <div style="color: rgba(243, 207, 178, 0.82)">
          *启动次数<span style="color: #ffe98d">(前3)</span>的设备种类的启动情况
        </div>
      </div>
    </div>
    <!-- 门店耗材使用排行 -->
    <!--只有一台设备的时候，隐藏   v-show="isGdDevice == 1 ? 1 : 0 && selectDeviceBaseInfo.deviceNum > 1 ? 1 : 0" -->

    <div
      class="content bg10 mdhcsyph"
          v-show="
            isGdDevice == 1 ? 1 : 0 && selectDeviceBaseInfo.deviceNum > 1 ? 1 : 0
          "
    >
      <div class="titleDiv">
        <div class="bigTitle" style="color: #fefaef">门店耗材使用排行</div>
      </div>
      <div class="tableDiv">
        <div class="redTable" style="width: 68%">
          <table
            style="width: 32.5%"
            v-for="(item, index) in selectShopConUseTop"
            :key="index"
          >
            <thead>
              <tr>
                <th>门店</th>
                <th>{{ item[0].consumableName }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(lititem, index2) in item" :key="index2">
                <td style="">
                  {{ lititem.shopName }}
                </td>
                <td>{{ lititem.startUpNum }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        class="tipDiv wow flipInX"
        data-wow-delay="1.5s"
        data-wow-duration="1.5s"
        style="color: rgba(243, 207, 178, 0.82)"
      >
        <div>为您分析热门耗材在每个门店得使用情况</div>
        <div>
          *启动次数<span style="color: #ffe98d">(前3)，</span
          >的设备种类的耗材使用排行(按门店排行)
        </div>
      </div>
    </div>
    <!--排行总结-->
    <!-- v-show="isMoremeDevice == 0 ? 1 : 0" -->
    <div
      class="content bg5 datadetails"
      v-bind:style="{ height: divHeight }"
      v-show="isMoremeDevice == 0 ? 1 : 0"
    >
      <ul>
        <li>检测顾客情况</li>
        <li>案例组数排行</li>
        <li>色斑类型症状分布</li>
        <li>顾客分布情况</li>
        <li>门店案例排行<span> (红榜)</span></li>
        <li>症状市场分析</li>
        <li>检测案例情况</li>
        <li>门店案例排行<span> (黑榜)</span></li>
        <li>产品曝光度统计</li>
        <li>门店检测案例情况</li>
        <li>症状分布</li>
        <li>症状分布对比</li>
      </ul>
      <div class="bigtitle">在该板块，艾摩米总结了</div>
      <div class="lititle">
        所有顾客的检测情况，并提供全国皮肤症状大数据与市场分析
      </div>
      <div class="tip">
        *您尚未与艾摩米合作检测仪项目，所以暂无检测相关数据可查看，如您有检测仪，<br />可能是设备归属问题，请联系客服处理
      </div>
    </div>
    <!--年度数据概览首页-->
    <div v-show="isMoremeDevice == 1 ? 1 : 0">
      <div class="content bg13">
        <div
          class="samllTit wow flipInX"
          data-wow-delay="0.5s"
          data-wow-duration="1.5s"
        >
          最后为您呈现
        </div>
        <div
          class="bigTit wow flipInX"
          data-wow-delay="1s"
          data-wow-duration="1.5s"
        >
          检测数据详情
        </div>
        <div class="arrow">
          <img
            src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/07_%E8%BF%9B%E5%85%A5%E9%A1%B5%E7%AE%AD%E5%A4%B4.png"
            alt=""
          /><img
            src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/08_%E8%BF%9B%E5%85%A5%E9%A1%B5%E7%AE%AD%E5%A4%B4.png"
            alt=""
          /><img
            src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/09_%E8%BF%9B%E5%85%A5%E9%A1%B5%E7%AE%AD%E5%A4%B4.png"
            alt=""
          />
        </div>
      </div>

      <!-- 检测顾客情况 -->
      <div
        class="content bg4 detectionCase"
        v-bind:style="{ height: divHeight }"
      >
        <div
          class="title wow flipInX"
          data-wow-delay="0.5s"
          data-wow-duration="1.5s"
        >
          <div class="bigTitle">检测顾客情况</div>
          <div class="smallTilte">
            顾客累计<span>{{ ClientCount }}</span
            >位 今年新增<span>{{ clientCounts.clientCountThisYear }}</span
            >位
          </div>
        </div>
        <div class="startNumCanvas">
          <div class="barChart" ref="barChart1"></div>
        </div>
      </div>
      <!-- 顾客分布情况 -->
      <!--只有一台设备的时候，隐藏门店检测顾客情况-->
      <div
        class="content bg15 detectionCase"
        v-bind:style="{ height: divHeight }"
        v-show="selectDeviceBaseInfo.deviceNum > 1 ? 1 : 0"
      >
        <div
          class="title wow flipInX"
          data-wow-delay="0.5s"
          data-wow-duration="1.5s"
        >
          <div class="bigTitle">顾客分布情况</div>
          <div class="smallTilte">
            顾客分布于全国<span>{{ clientProvinceCount }}</span
            >个省份
          </div>
        </div>
        <div class="startNumCanvas">
          <div class="barChart" ref="barChart2"></div>
        </div>
        <div
          class="subheading4Div wow flipInX"
          data-wow-delay="1.5s"
          data-wow-duration="1.5s"
          style="top: 88%"
        >
          <div>&nbsp;</div>
          <div>*图表最多展示12个省的顾客分布，如需查看更多，请联系客服</div>
        </div>
      </div>
      <!-- 检测案例情况 -->
      <div
        class="content bg16 detectionCase"
        v-bind:style="{ height: divHeight }"
      >
        <div
          class="title wow flipInX"
          data-wow-delay="0.5s"
          data-wow-duration="1.5s"
        >
          <div class="bigTitle">检测案例情况</div>
          <div class="smallTilte">
            累计案例<span>{{ CasegroupCount }}</span
            >组 今年新增<span>{{ CasegroupCountThisYear }}</span
            >组
          </div>
        </div>
        <div class="startNumCanvas">
          <div class="barChart" ref="barChart3"></div>
        </div>
      </div>
      <!--门店检测顾客情况-->
      <!--只有一台设备的时候，隐藏门店检测顾客情况-->
      <div
        class="content bg17 shopCaseqk"
        v-bind:style="{ height: divHeight }"
        v-show="selectDeviceBaseInfo.deviceNum > 1 ? 1 : 0"
      >
        <div class="title">门店检测顾客情况</div>
        <div class="ulDiv">
          <ul>
            <li>
              <div class="littitle">
                案例数超<span class="red">100</span>的门店
                <span>共{{ ClientCasegroupCountList[0] }}家</span>
              </div>
              <ul>
                <li
                  v-for="(item, index) in CasegroupTotalOneMemberList"
                  :key="index"
                >
                  <div>{{ item.name }}</div>
                  <div>{{ item.total }}</div>
                </li>
              </ul>
            </li>
            <li>
              <div class="littitle">
                案例数<span class="red">30-100</span>的门店
                <span>共{{ ClientCasegroupCountList[1] }}家</span>
              </div>
              <ul>
                <li
                  v-for="(item, index) in CasegroupTotalTwoMemberList"
                  :key="index"
                >
                  <div>{{ item.name }}</div>
                  <div>{{ item.total }}</div>
                </li>
              </ul>
            </li>
            <li>
              <div class="littitle">
                案例数<span class="red">10-30</span>的门店
                <span>共{{ ClientCasegroupCountList[2] }}家</span>
              </div>
              <ul>
                <li
                  v-for="(item, index) in CasegroupTotalThreeMemberList"
                  :key="index"
                >
                  <div>{{ item.name }}</div>
                  <div>{{ item.total }}</div>
                </li>
              </ul>
            </li>
            <li>
              <div class="littitle">
                案例数<span class="red">10</span>以下的门店
                <span>共{{ ClientCasegroupCountList[3] }}家</span>
              </div>
              <ul>
                <li
                  v-for="(item, index) in CasegroupTotalFourMemberList"
                  :key="index"
                >
                  <div>{{ item.name }}</div>
                  <div>{{ item.total }}</div>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="tip">
          *建议案例数较多的门店总结经验分享，相互学习可以有效提升门店服务水平
        </div>
      </div>
      <!-- 案例组数排行 -->
      <div class="content bg18 caseGrounp" v-bind:style="{ height: divHeight }">
        <div
          class="titleDiv wow flipInX"
          data-wow-delay="0.5s"
          data-wow-duration="1.5s"
        >
          <div class="bigTitle">案例组数排行</div>
        </div>
        <div
          class="deviceTable wow pulse"
          data-wow-delay="1s"
          data-wow-duration="1.5s"
        >
          <table>
            <thead>
              <tr>
                <th style="width: 33%; text-align: center">顾客名</th>
                <th>所属门店</th>
                <th>案例数</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in ClientCasegroupCountTopTenList"
                :key="index"
              >
                <td style="width: 33%; text-align: center">
                  {{ item.cname }}
                </td>
                <td>{{ item.dmname }}</td>
                <td>{{ item.cgcount }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="subheading4Div wow flipInX"
          data-wow-delay="1.5s"
          data-wow-duration="1.5s"
          style="top: 84%; line-height: 1rem"
        >
          <div>全国平均每位顾客拥有1.5组案例</div>
          <div>
            建议1个顾客到店1次可拍正脸、左、右侧脸3组案例；案例组数越多，说明顾客对门店服务越认可
          </div>
        </div>
      </div>
      <!--门店案例排行红榜 -->
      <!--只有一台设备的时候，隐藏-->
      <div
        class="content bg5 shopCaseph"
        v-show="selectDeviceBaseInfo.deviceNum > 1 ? 1 : 0"

      >
        <div class="title">
          <div class="titles">
            <div class="bigtitle">门店案例排行</div>
            <div class="tip">红榜</div>
          </div>
          <div class="icon">
            <div
              class="iconbox"
              v-for="(item, index) in MemberCasegroupCountTopFiveThisYearList"
              :key="index"
            >
              <div :class="'se' + index"></div>
              <div>{{ item.dmname }}</div>
            </div>
          </div>
        </div>
        <div class="tableandbiao">
          <ul class="table">
            <li class="hd">
              <div>门店</div>
              <div>新增案例</div>
            </li>
            <li
              v-for="(item, index) in MemberCasegroupCountTopFiveThisYearList"
              :key="index"
            >
              <div>{{ item.dmname }}</div>
              <div>{{ item.total }}</div>
            </li>
          </ul>
          <div class="startNumCanvas">
            <div class="barChart" ref="barChart4"></div>
          </div>
        </div>
        <div
          class="tips"
        >
          建议由优秀门店分享经验，相互学习提升服务水平，艾摩米希望与客户携手共进，帮助客户产生更多价值。<br />
          *2023年新增案例最多的门店，及其每月新增案例情况（红榜）
        </div>
      </div>
      <!--门店案例排行黑榜-->
      <!--只有一台设备的时候，隐藏-->
      <div
        class="content bg5 shopCaseph shopCasephhei"
        v-show="selectDeviceBaseInfo.deviceNum > 1 ? 1 : 0"
        v-bind:style="{ height: divHeight }"
      >
        <div class="title">
          <div class="titles">
            <div class="bigtitle">门店案例排行</div>
            <div class="tip">黑榜</div>
          </div>
          <div class="icon">
            <div
              class="iconbox"
              v-for="(
                item, index
              ) in MemberCasegroupCountBottomFiveThisYearList"
              :key="index"
            >
              <div :class="'se' + index"></div>
              <div>{{ item.dmname }}</div>
            </div>
          </div>
        </div>
        <div class="tableandbiao">
          <ul class="table">
            <li class="hd">
              <div>门店</div>
              <div>新增案例</div>
            </li>
            <li
              v-for="(
                item, index
              ) in MemberCasegroupCountBottomFiveThisYearList"
              :key="index"
            >
              <div>{{ item.dmname }}</div>
              <div>{{ item.total }}</div>
            </li>
          </ul>
          <div class="startNumCanvas">
            <div class="barChart" ref="barChart5"></div>
          </div>
        </div>
        <div class="tips">
          如需设备培训，可在小程序首页-申请专家下店培训板块进行申请，或使用成长值兑换店销、下店培训服务<br />
          *2023年新增案例最少的门店，及其每月新增案例情况（黑榜）
        </div>
      </div>

      <!--症状分布(总体)-->
      <div class="content bg5 symptomDistribution symptomDistributionsb">
        <div class="title">症状分布对比</div>
        <div class="yuanbiao">
          <div class="one">
            <div class="txt">我的</div>
            <div class="tu">
              <div class="barChart" ref="barChart20"></div>
            </div>
            <ul class="label">
              <li>
                <div></div>
                <div>敏感</div>
              </li>
              <li>
                <div></div>
                <div>皱纹</div>
              </li>
              <li>
                <div></div>
                <div>座疮</div>
              </li>
              <li>
                <div></div>
                <div>毛孔</div>
              </li>
              <li>
                <div></div>
                <div>色沉</div>
              </li>
              <li>
                <div></div>
                <div>黑头</div>
              </li>
            </ul>
          </div>
          <div class="one">
            <div class="txt">全国</div>
            <div class="tu">
              <div class="barChart" ref="barChart21"></div>
            </div>
            <ul class="label">
              <li>
                <div></div>
                <div>敏感</div>
              </li>
              <li>
                <div></div>
                <div>皱纹</div>
              </li>
              <li>
                <div></div>
                <div>座疮</div>
              </li>
              <li>
                <div></div>
                <div>毛孔</div>
              </li>
              <li>
                <div></div>
                <div>色沉</div>
              </li>
              <li>
                <div></div>
                <div>黑头</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="tips">
          掌握面诊知识，才能把握每位顾客的精准需求。面诊训练营已帮助上千位美容师掌握面诊技能<br />
          *我的所有检测症状数据与全国检测大数据的六大维度问题比例
        </div>
      </div>
      <!--症状分布1-->
      <div
        class="content bg17 symptomDistribution"
        v-bind:style="{ height: divHeight }"
      >
        <div class="title">症状分布</div>
        <div class="yuanbiao">
          <div class="one">
            <div class="txt">我的</div>
            <div class="typetxt">敏感维度</div>
            <div class="tu">
              <div class="barChart" ref="barChart6"></div>
            </div>
            <ul class="label">
              <li>
                <div></div>
                <div>无</div>
              </li>
              <li>
                <div></div>
                <div>轻度</div>
              </li>
              <li>
                <div></div>
                <div>中度</div>
              </li>
              <li>
                <div></div>
                <div>重度</div>
              </li>
            </ul>
          </div>
          <div class="one">
            <div class="txt">全国</div>
            <div class="tu">
              <div class="barChart" ref="barChart7"></div>
            </div>

            <ul class="label">
              <li>
                <div></div>
                <div>无</div>
              </li>
              <li>
                <div></div>
                <div>轻度</div>
              </li>
              <li>
                <div></div>
                <div>中度</div>
              </li>
              <li>
                <div></div>
                <div>重度</div>
              </li>
            </ul>
          </div>
          <div class="one">
            <div class="typetxt">皱纹维度</div>
            <div class="tu">
              <div class="barChart" ref="barChart8"></div>
            </div>

            <ul class="label">
              <li>
                <div></div>
                <div>无</div>
              </li>
              <li>
                <div></div>
                <div>轻度</div>
              </li>
              <li>
                <div></div>
                <div>中度</div>
              </li>
              <li>
                <div></div>
                <div>重度</div>
              </li>
            </ul>
          </div>
          <div class="one">
            <div class="tu">
              <div class="barChart" ref="barChart9"></div>
            </div>

            <ul class="label">
              <li>
                <div></div>
                <div>无</div>
              </li>
              <li>
                <div></div>
                <div>轻度</div>
              </li>
              <li>
                <div></div>
                <div>中度</div>
              </li>
              <li>
                <div></div>
                <div>重度</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="tips">
          *我的所有检测案例的症状数据与全国检测症状大数据的问题对比
        </div>
      </div>
      <!--症状分布2-->
      <div
        class="content bg18 symptomDistribution"
        v-bind:style="{ height: divHeight }"
      >
        <div class="title">症状分布</div>
        <div class="yuanbiao">
          <div class="one">
            <div class="txt">我的</div>
            <div class="typetxt">痤疮维度</div>
            <div class="tu">
              <div class="barChart" ref="barChart10"></div>
            </div>
            <ul class="label">
              <li>
                <div></div>
                <div>无</div>
              </li>
              <li>
                <div></div>
                <div>轻度</div>
              </li>
              <li>
                <div></div>
                <div>中度</div>
              </li>
              <li>
                <div></div>
                <div>重度</div>
              </li>
            </ul>
          </div>
          <div class="one">
            <div class="txt">全国</div>
            <div class="tu">
              <div class="barChart" ref="barChart11"></div>
            </div>

            <ul class="label">
              <li>
                <div></div>
                <div>无</div>
              </li>
              <li>
                <div></div>
                <div>轻度</div>
              </li>
              <li>
                <div></div>
                <div>中度</div>
              </li>
              <li>
                <div></div>
                <div>重度</div>
              </li>
            </ul>
          </div>
          <div class="one">
            <div class="typetxt">毛孔维度</div>
            <div class="tu">
              <div class="barChart" ref="barChart12"></div>
            </div>

            <ul class="label">
              <li>
                <div></div>
                <div>无</div>
              </li>
              <li>
                <div></div>
                <div>轻度</div>
              </li>
              <li>
                <div></div>
                <div>中度</div>
              </li>
              <li>
                <div></div>
                <div>重度</div>
              </li>
            </ul>
          </div>
          <div class="one">
            <div class="tu">
              <div class="barChart" ref="barChart13"></div>
            </div>

            <ul class="label">
              <li>
                <div></div>
                <div>无</div>
              </li>
              <li>
                <div></div>
                <div>轻度</div>
              </li>
              <li>
                <div></div>
                <div>中度</div>
              </li>
              <li>
                <div></div>
                <div>重度</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="tips">
          *我的所有检测案例的症状数据与全国检测症状大数据的问题对比
        </div>
      </div>
      <!--症状分布3-->
      <div
        class="content bg5 symptomDistribution"
        v-bind:style="{ height: divHeight }"
      >
        <div class="title">症状分布</div>
        <div class="yuanbiao">
          <div class="one">
            <div class="txt">我的</div>
            <div class="typetxt">色沉维度</div>
            <div class="tu">
              <div class="barChart" ref="barChart14"></div>
            </div>
            <ul class="label">
              <li>
                <div></div>
                <div>无</div>
              </li>
              <li>
                <div></div>
                <div>轻度</div>
              </li>
              <li>
                <div></div>
                <div>中度</div>
              </li>
              <li>
                <div></div>
                <div>重度</div>
              </li>
            </ul>
          </div>
          <div class="one">
            <div class="txt">全国</div>
            <div class="tu">
              <div class="barChart" ref="barChart15"></div>
            </div>

            <ul class="label">
              <li>
                <div></div>
                <div>无</div>
              </li>
              <li>
                <div></div>
                <div>轻度</div>
              </li>
              <li>
                <div></div>
                <div>中度</div>
              </li>
              <li>
                <div></div>
                <div>重度</div>
              </li>
            </ul>
          </div>
          <div class="one">
            <div class="typetxt">黑头维度</div>
            <div class="tu">
              <div class="barChart" ref="barChart16"></div>
            </div>

            <ul class="label">
              <li>
                <div></div>
                <div>无</div>
              </li>
              <li>
                <div></div>
                <div>轻度</div>
              </li>
              <li>
                <div></div>
                <div>中度</div>
              </li>
              <li>
                <div></div>
                <div>重度</div>
              </li>
            </ul>
          </div>
          <div class="one">
            <div class="tu">
              <div class="barChart" ref="barChart17"></div>
            </div>

            <ul class="label">
              <li>
                <div></div>
                <div>无</div>
              </li>
              <li>
                <div></div>
                <div>轻度</div>
              </li>
              <li>
                <div></div>
                <div>中度</div>
              </li>
              <li>
                <div></div>
                <div>重度</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="tips">
          *我的所有检测案例的症状数据与全国检测症状大数据的问题对比
        </div>
      </div>
      <!--色斑类型症状分布-->
      <div
        class="content bg5 symptomDistribution symptomDistributionsb"
        v-bind:style="{ height: divHeight }"
      >
        <div class="title">色斑类型症状分布</div>
        <div class="yuanbiao">
          <div class="one">
            <div class="txt">我的</div>
            <div class="tu">
              <div class="barChart" ref="barChart18"></div>
            </div>
            <ul class="label">
              <li>
                <div></div>
                <div>老年斑</div>
              </li>
              <li>
                <div></div>
                <div>黄褐斑</div>
              </li>
              <li>
                <div></div>
                <div>色素痣</div>
              </li>
              <li>
                <div></div>
                <div>褐青色痣</div>
              </li>
              <li>
                <div></div>
                <div>雀斑</div>
              </li>
              <li>
                <div></div>
                <div>晒斑</div>
              </li>
            </ul>
          </div>
          <div class="one">
            <div class="txt">全国</div>
            <div class="tu">
              <div class="barChart" ref="barChart19"></div>
            </div>
            <ul class="label">
              <li>
                <div></div>
                <div>老年斑</div>
              </li>
              <li>
                <div></div>
                <div>黄褐斑</div>
              </li>
              <li>
                <div></div>
                <div>色素痣</div>
              </li>
              <li>
                <div></div>
                <div>褐青色痣</div>
              </li>
              <li>
                <div></div>
                <div>雀斑</div>
              </li>
              <li>
                <div></div>
                <div>晒斑</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="tips" style="bottom: 1.5rem">
          *我的所有检测案例的色斑类型与全国检测色斑类型大数据的问题对比
        </div>
      </div>
      <!--症状市场分析-->
      <div
        class="content bg15 marketAnalyst"
      >
        <div class="title">症状市场分析</div>
        <ul>
          <li>
            <div class="littile">我的趋势分析</div>
            <div>
              {{ MarketAnalyze[0] }}<br /><br />
              {{ MarketAnalyze[1] }}
            </div>
          </li>
          <li>
            <div class="littile">全国趋势分析</div>
            <div>
              全国范围内，敏感、皱纹、痤疮问题是到店人群较为突出的重点问题<br /><br />
              大部分顾客都有普通程度的毛孔、黑头、色沉问题<br /><br />
              大部分顾客都有色素痣问题，晒斑、黄褐斑、雀斑是更常见的斑的问题
            </div>
          </li>
        </ul>
        <div class="tip">
          建议明年的市场、品项可以结合我的顾客症状与全国大数据做决定。<br />
          如围绕突出的皮肤问题做明星疗程方案，或做店内的品项查漏补缺。可联系客服安排咨询服务
        </div>
      </div>
      <!-- 产品曝光度统计 -->
      <div
        class="content bg16 sensitometryStats"
        v-bind:style="{ height: divHeight }"
      >
        <div
          class="titleDiv wow flipInX"
          data-wow-delay="0.5s"
          data-wow-duration="1.5s"
        >
          <div class="bigTitle">产品曝光度统计</div>
        </div>
        <div
          class="deviceTable wow pulse"
          data-wow-delay="1s"
          data-wow-duration="1.5s"
        >
          <table>
            <thead>
              <tr>
                <th style="width: 50%; text-align: center">产品/仪器</th>
                <th>被推荐次数</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in GoodsList" :key="index">
                <td style="text-align: center">
                  {{ item.goodsName }}
                </td>
                <td>{{ item.total }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="subheading4Div wow flipInX"
          data-wow-delay="1.5s"
          data-wow-duration="1.5s"
        >
          <div>
            根据顾客的症状，在顾客的检测报告中推荐预设的产品/仪器。该功能可有效向顾客宣传自家产品。
          </div>
          <div>*产品曝光度统计<span>（前10）</span><a>查看配置教程视频</a></div>
        </div>
      </div>
    </div>
    <!-- 只有一台设备 -->
    <div
      class="content bg4 datadetails"
      v-show="selectDeviceBaseInfo.deviceNum == 1"
    >
      <ul>
        <li>设备开机排行<span> (黑榜)</span></li>
        <li>顾客分布情况</li>
        <li>门店案例排行<span> (红榜)</span></li>
        <li>门店开机情况</li>
        <li>门店检测顾客情况</li>
        <li>门店案例排行<span> (黑榜)</span></li>
        <li>门店耗材使用排行</li>
      </ul>
      <div class="bigtitle">此外，艾摩米为拥有多台设备</div>
      <div class="lititle">
        客户分析了不同门店、设备的使用状况，帮助客户更好评估多家门店的<br />经营情况
      </div>
      <div class="tip">
        *您目前仅有1台设备，所以暂无多门店、多设备的分析，如您有多台设备，可能是设备归属问题，<br />请联系客服处理
      </div>
    </div>
    <!--尾页-->
    <div class="content bg19">
      <div class="txt1">新的一年</div>

      <div class="end">
        <div class="txtDiv">
          <div class="txt">艾摩米与您携手共进</div>
          <div class="txt">祝您事业一往无前</div>
        </div>

        <div>
          <img
            src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/kefuqrcode.jpg"
            alt=""
          />
          <div>长按扫一扫联系客服</div>
        </div>
      </div>
      <div class="endTxt">如需更多数据、解读指导，请联系客服</div>
    </div>
    <!--横屏提示-->
    <div class="henping" v-show="tipsshow" @click="tipshowbtn">
      <img
        src="https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/miniProgram/annualReport/henping.png"
        alt=""
      />
      <div>建议横屏观看</div>
    </div>
  </div>
</template>
<script
  type="text/javascript"
  src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"
></script>
<script type="text/javascript" src="../js/wow.js"></script>

<script>
import {
  info,
  selectDeviceBaseInfo,
  selectDeviceStartUp,
  selectDeviceStartUpTopDesc,
  selectShopInfo,
  selectDeviceStartUpTopAsc,
  selectEmpOperateTop,
  selectConUseInfo,
  selectShopConUseTop,
  selectHasCheckDevice,
  getMoremeDevice,
  getCasegroupAvgCount,
  getClientCount,
  getCasegroupCount,
  getClientCountThisYear,
  getClientCountMonthList,
  getClientProvinceCount,
  getClientProvinceCountList,
  getCasegroupCountThisYear,
  getCasegroupCountMonthList,
  getClientCasegroupCountList,
  getCasegroupTotalOneMemberList,
  getCasegroupTotalTwoMemberList,
  getCasegroupTotalThreeMemberList,
  getCasegroupTotalFourMemberList,
  getClientCasegroupCountTopTenList,
  getMemberCasegroupCountTopFiveThisYearList,
  getTopFiveCasegroupCountList,
  getMemberCasegroupCountBottomFiveThisYearList,
  getBottomFiveCasegroupCountList,
  getSensityProportionList,
  getWrinkleProportionList,
  getAcneProportionList,
  getPoreProportionList,
  getSpotProportionList,
  getBlackheadProportionList,
  getSpotAnalyzeList,
  getMarketAnalyze,
  getGoodsList,
  selectShopStartUpTop,
} from "@/api/indexdb"; //"@/api/indexbd";

export default {
  name: "HomeView",
  data() {
    return {
      orgName: "", //机构名称
      divHeight: "",
      cooperationTime: "",
      //机构信息
      info: [],
      //今年门店概况
      orgData: {},
      //设备基本情况
      selectDeviceBaseInfo: {
        deviceNum: null,
        yearDeviceTypeInfoList: [
          {
            deviceTypeName: null,
            startUpNum: null,
            avgStartUpNum: null,
          },
        ],
      },
      //判断是否有光电设备
      isGdDevice: 0,
      //判断是否有检测设备
      isMoremeDevice: 0,

      //设备启动次数
      selectDeviceStartUp: [],
      //设备开机排行榜，红榜
      selectDeviceStartUpTopDesc: [],
      //设备开机排行榜，黑榜
      selectDeviceStartUpTopAsc: [],
      //门店开机情况
      ShopStartUpTop: [],
      //员工操作排行
      selectEmpOperateTop: [],
      //设备耗材使用排行
      selectConUseInfo: [],
      //门店耗材使用排行
      selectShopConUseTop: [],
      //检测仪测试用的帐号
      account: "",
      //皮肤检测概况（顾客累计数量）
      ClientCount: "",
      //皮肤检测概况(案例数量)
      CasegroupCount: "",
      //皮肤检测概况(平均案例数量)
      CasegroupAvgCount: "",
      //检测顾客情况
      clientCounts: [],
      //顾客分布情况（顾客分布省份数量）
      clientProvinceCount: "",
      //顾客分布情况（顾客分布的省份数据）
      clientProvinceCountList: [],
      //检测案例情况（今年新增案例）
      CasegroupCountThisYear: "",
      //检测案例情况（每月新增案例列表）
      CasegroupCountMonthLis: [],
      //门店检测顾客情况
      ClientCasegroupCountList: [],
      //门店检测顾客情况(100)
      CasegroupTotalOneMemberList: [],
      //门店检测顾客情况(30-100)
      CasegroupTotalTwoMemberList: [],
      //门店检测顾客情况(10-30)
      CasegroupTotalThreeMemberList: [],
      //门店检测顾客情况(10以下)
      CasegroupTotalFourMemberList: [],
      //案例组数排行(列表)
      ClientCasegroupCountTopTenList: [],
      //门店案例排行（红榜列表）
      MemberCasegroupCountTopFiveThisYearList: [],
      //门店案例排行（红榜图表）
      TopFiveCasegroupCountList: [],
      //门店案例排行（黑榜列表）
      MemberCasegroupCountBottomFiveThisYearList: [],
      //门店案例排行（黑榜图表）
      BottomFiveCasegroupCountList: [],

      //症状分布（敏感）
      SensityProportionList: [],
      //症状分布（皱纹）
      WrinkleProportionList: [],
      //症状分布（痤疮）
      AcneProportionList: [],
      //症状分布（毛孔）
      PoreProportionList: [],
      //症状分布（色沉）
      SpotProportionList: [],
      //症状分布（黑头）
      getBlackheadProportionList: [],
      //症状分布（色斑）
      SpotAnalyzeList: [],
      //症状市场分析
      MarketAnalyze: [],
      //产品曝光度统计
      GoodsList: [],
      comUseData: [
        {
          type: "检测仪检测仪",
          num: 1,
          qd: 2,
          pjqd: 3,
          qgqd: 4,
        },
        {
          type: "6666",
          num: 17,
          qd: 27,
          pjqd: 36,
          qgqd: 47,
        },
        {
          type: "123",
          num: 1,
          qd: 2,
          pjqd: 3,
          qgqd: 4,
        },
        {
          type: "6666",
          num: 17,
          qd: 27,
          pjqd: 36,
          qgqd: 47,
        },
        {
          type: "123",
          num: 1,
          qd: 2,
          pjqd: 3,
          qgqd: 4,
        },
        {
          type: "6666",
          num: 17,
          qd: 27,
          pjqd: 36,
          qgqd: 47,
        },
      ],
      tipsshow: 0,
    };
  },
  components: {},
  mounted() {
    let _this=this;
    var wow = new this.$wow.WOW({
      live: true,
    });

    wow.init();
    // 初始化 echarts
    window.addEventListener("resize", this.renderResize, true);
    _this.renderResize2();
    this.getInit();
  },
  methods: {
    tipshowbtn() {
      this.tipsshow = 0;
    },
    toWeixin() {
      wx.miniProgram.navigateTo({
        URL: "pages/index/index",
      });
    },
    //初始化方法
    getInit() {
      //获取当前屏幕大小，一个Div的高度
      let width = document.documentElement.clientWidth;
      this.divHeight = 0.563 * width + "px";
      console.log("divHeight", this.divHeight);
      this.infofn();
    },
    //加载第二批接口
    loadSecond() {},
    initBarChart() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart);
      //获取数据集合
      let dataarr = this.selectDeviceStartUp.deviceStartUpByMonthList;
      const result = dataarr.map((obj) => obj.num);
      console.log("获取数组集合---->", result);
      let option = {
        legend: {
          textStlye: {
            fontSize: 8,
          },
        },
        grid: {
          left: "10%",
          right: "5%",
          top: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ],
          axisTick: {
            show: false,
          }, //刻度值

          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          }, // 坐标轴的线
          axisLabel: {
            show: true,
            fontSize: 8,
            color: "#666666",
          }, //坐标轴的文字
        },

        yAxis: {
          type: "value",
          // max: function (value) {
          //   return Math.floor(value.max + 0.2 * value.max);
          // },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 8,
            color: "#666666",
          },
          splitLine: {
            show: true,
            interval: 0,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          }, //分割线
        },
        series: [
          {
            data: result,
            label: {
              show: true,
              position: "top",
              fontSize: 5,
              color: "#666666",
              fontWeight: "bold",
            },
            barWidth: "48%",
            type: "bar",
            showBackground: true,
            color: "rgb(193, 36, 32)",

            backgroundStyle: {
              color: "rgba(0,0,0,0)",
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initBarChart1() {
      // 通过 $ref 进行挂载
      let _this = this;
      let myChart = this.$echarts.init(this.$refs.barChart1);
      let option = {
        legend: {
          textStlye: {
            fontSize: 8,
          },
        },
        grid: {
          left: "10%",
          right: "5%",
          top: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ],
          axisTick: {
            show: false,
          }, //刻度值

          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          }, // 坐标轴的线
          axisLabel: {
            show: true,
            fontSize: 8,
            color: "#666666",
          }, //坐标轴的文字
        },

        yAxis: {
          type: "value",
          // max: function (value) {
          //   return Math.floor(value.max + 0.2 * value.max);
          // },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 8,
            color: "#666666",
          },
          splitLine: {
            show: true,
            interval: 0,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          }, //分割线
        },
        series: [
          {
            itemStyle: {
              borderWidth: 0, // 设置边边宽度，可以根据需要调整此值
            },
            data: JSON.parse(_this.clientCounts.clientCountMonthList),
            label: {
              show: true,
              position: "top",
              fontSize: 5,
              color: "#666666",
              fontWeight: "bold",
            },
            barWidth: "48%",
            type: "bar",
            showBackground: true,
            color: "rgb(193, 36, 32)",

            backgroundStyle: {
              color: "rgba(0,0,0,0)",
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //顾客分布情况
    initBarChart2() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart2);
      let _this = this;
      //上线需要删除
      /* let newData=[
        {value: 210, name: '海外'},
        {value: 90, name: '长沙市'},
        {value: 90, name: '美国 (U.S.A)'},
        {value: 80, name: '河北省'},
        {value: 70, name: '北京市'},
        {value: 70, name: '广州市'},
        {value: 50, name: '武汉市'},
        {value: 50, name: '内蒙古自治区'},
        {value: 40, name: '广东省'},
        {value: 40, name: '山西省'}
      ];
      const provinceNames = [
        "海外",
        "长沙市",
        "美国 (U.S.A)",
        "河北省",
        "北京市",
        "广州市",
        "武汉市",
        "内蒙古自治区",
        "广东省",
        "山西省"
      ]*/
      let datas = JSON.parse(_this.clientProvinceCountList);
      const newData = datas.map((item) => {
        return {
          value: item.total, // 替换 total 的值为 total * 10
          name: item.provinceName, // 替换 provinceName 的值为大写形式
        };
      });
      const provinceNames = datas.map((item) => (item.provinceName).slice(0,4));

      let option = {
        legend: {
          textStlye: {
            fontSize: 8,
          },
        },
        grid: {
          left: "10%",
          right: "5%",
          top: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: provinceNames,
          axisTick: {
            show: false,
          }, //刻度值

          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          }, // 坐标轴的线
          axisLabel: {
            show: true,
            fontSize: 6,
            color: "#666666",
            rotate: -90,
          }, //坐标轴的文字
        },

        yAxis: {
          type: "value",
          // max: function (value) {
          //   return Math.floor(value.max + 0.2 * value.max);
          // },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 8,
            color: "#666666",
          },
          splitLine: {
            show: true,
            interval: 0,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          }, //分割线
        },
        series: [
          {
            itemStyle: {
              borderWidth: 0, // 设置边边宽度，可以根据需要调整此值
            },
            data: newData,
            label: {
              show: true,
              position: "top",
              fontSize: 5,
              color: "#666666",
              fontWeight: "bold",
            },
            barWidth: "48%",
            type: "bar",
            showBackground: true,
            color: "rgb(193, 36, 32)",

            backgroundStyle: {
              color: "rgba(0,0,0,0)",
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //检测案例情况
    initBarChart3() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart3);
      let _this = this;
      let option = {
        legend: {
          textStlye: {
            fontSize: 8,
          },
        },
        grid: {
          left: "10%",
          right: "5%",
          top: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ],
          axisTick: {
            show: false,
          }, //刻度值

          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          }, // 坐标轴的线
          axisLabel: {
            show: true,
            fontSize: 8,
            color: "#666666",
          }, //坐标轴的文字
        },

        yAxis: {
          type: "value",
          // max: function (value) {
          //   return Math.floor(value.max + 0.2 * value.max);
          // },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 8,
            color: "#666666",
          },
          splitLine: {
            show: true,
            interval: 0,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          }, //分割线
        },
        series: [
          {
            itemStyle: {
              borderWidth: 0, // 设置边边宽度，可以根据需要调整此值
            },
            data: JSON.parse(_this.CasegroupCountMonthLis),
            label: {
              show: true,
              position: "top",
              fontSize: 5,
              color: "#666666",
              fontWeight: "bold",
            },
            barWidth: "48%",
            type: "bar",
            showBackground: true,
            color: "rgb(193, 36, 32)",

            backgroundStyle: {
              color: "rgba(0,0,0,0)",
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //检测案例情况
    initBarChart4() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart4);
      let _this = this;
      let series = [];
      let bgcolor = ["#C12420", "#D25D51", "#DE8573", "#EDBAA0", "#F3CFB2"];
      for (let i = 0; i < _this.TopFiveCasegroupCountList.length; i++) {
        series.push({
          itemStyle: {
            borderWidth: 0, // 设置边边宽度，可以根据需要调整此值
          },
          data: _this.TopFiveCasegroupCountList[i],
          barWidth: "20%",
          type: "bar",
          showBackground: true,
          color: bgcolor[i],
          barGap: "0" /*多个并排柱子设置柱子之间的间距*/,
          barCategoryGap: "0" /*多个并排柱子设置柱子之间的间距*/,
          backgroundStyle: {
            color: "rgba(0,0,0,0)",
          },
        });
      }
      let option = {
        legend: {
          textStlye: {
            fontSize: 8,
          },
        },
        grid: {
          left: "10%",
          right: "5%",
          top: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ],
          axisTick: {
            show: false,
          }, //刻度值

          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          }, // 坐标轴的线
          axisLabel: {
            show: true,
            fontSize: 8,
            color: "#666666",
            rotate: -90,
          }, //坐标轴的文字
        },

        yAxis: {
          type: "value",
          // max: function (value) {
          //   return Math.floor(value.max + 0.2 * value.max);
          // },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 8,
            color: "#666666",
          },
          splitLine: {
            show: true,
            interval: 0,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          }, //分割线
        },
        series: series,
      };
      myChart.setOption(option);
    },
    //检测案例情况
    initBarChart5() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart5);
      let _this = this;
      let series = [];
      let bgcolor1 = ["#C12420", "#D25D51", "#DE8573", "#EDBAA0", "#F3CFB2"];
      for (let i = 0; i < _this.BottomFiveCasegroupCountList.length; i++) {
        series.push({
          itemStyle: {
            borderWidth: 0, // 设置边边宽度，可以根据需要调整此值
          },
          data: _this.BottomFiveCasegroupCountList[i],
          barWidth: "20%",
          type: "bar",
          showBackground: true,
          color: bgcolor1[i],
          barGap: "0" /*多个并排柱子设置柱子之间的间距*/,
          barCategoryGap: "0" /*多个并排柱子设置柱子之间的间距*/,
          backgroundStyle: {
            color: "rgba(0,0,0,0)",
          },
        });
      }
      let option = {
        legend: {
          textStlye: {
            fontSize: 8,
          },
        },
        grid: {
          left: "10%",
          right: "5%",
          top: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ],
          axisTick: {
            show: false,
          }, //刻度值

          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          }, // 坐标轴的线
          axisLabel: {
            show: true,
            fontSize: 8,
            color: "#666666",
            rotate: -90,
          }, //坐标轴的文字
        },

        yAxis: {
          type: "value",
          // max: function (value) {
          //   return Math.floor(value.max + 0.2 * value.max);
          // },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 8,
            color: "#666666",
          },
          splitLine: {
            show: true,
            interval: 0,
            lineStyle: {
              color: "rgba(243,207,178,0.7)",
            },
          }, //分割线
        },
        series: series,
      };
      myChart.setOption(option);
    },
    //敏感维度（我的）
    initBarChart6() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart6);
      let _this = this;
      let datas = _this.SensityProportionList;
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: datas,
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#B8221E", "#D25D51", "#DE8573", "#EDBAA0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //敏感维度（全国）
    initBarChart7() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart7);
      let _this = this;
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: [114877, 2558797, 334319, 2183771],
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#B8221E", "#D25D51", "#DE8573", "#EDBAA0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //皱纹维度（我的）
    initBarChart8() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart8);
      let _this = this;
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: _this.WrinkleProportionList,
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#B8221E", "#D25D51", "#DE8573", "#EDBAA0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //皱纹维度（全国）
    initBarChart9() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart9);
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: [0, 2951858, 1604517, 635538],
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#B8221E", "#D25D51", "#DE8573", "#EDBAA0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //痤疮维度（我的）
    initBarChart10() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart10);
      let _this = this;
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: _this.AcneProportionList,
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#B8221E", "#D25D51", "#DE8573", "#EDBAA0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //痤疮维度（全国）
    initBarChart11() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart11);
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: [122072, 1596841, 247203, 3225810],
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#B8221E", "#D25D51", "#DE8573", "#EDBAA0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //毛孔维度（我的）
    initBarChart12() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart12);
      let _this = this;
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: _this.PoreProportionList,
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#B8221E", "#D25D51", "#DE8573", "#EDBAA0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //毛孔维度（全国）
    initBarChart13() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart13);
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: [24372, 3352378, 790964, 1024225],
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#B8221E", "#D25D51", "#DE8573", "#EDBAA0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initBarChart14() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart14);
      let _this = this;
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: _this.SpotProportionList,
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#B8221E", "#D25D51", "#DE8573", "#EDBAA0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initBarChart15() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart15);
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: [438, 3161644, 1425134, 605170],
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#B8221E", "#D25D51", "#DE8573", "#EDBAA0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initBarChart16() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart16);
      let _this = this;
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: _this.getBlackheadProportionList,
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#B8221E", "#D25D51", "#DE8573", "#EDBAA0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initBarChart17() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart17);
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: [0, 3435032, 1052291, 704631],
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#B8221E", "#D25D51", "#DE8573", "#EDBAA0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initBarChart18() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart18);
      let _this = this;
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: _this.SpotAnalyzeList,
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = [
                    "#C12420",
                    "#D25D51",
                    "#DE8573",
                    "#E7A58E",
                    "#EDBAA0",
                    "#F3CFB2",
                  ];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initBarChart19() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart19);
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: [591760, 1051271, 4606126, 611836, 949878, 2578835],
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = [
                    "#C12420",
                    "#D25D51",
                    "#DE8573",
                    "#E7A58E",
                    "#EDBAA0",
                    "#F3CFB2",
                  ];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initBarChart20() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart20);
      let _this = this;
      let allvalarr = [];
      //获取敏感的总和
      allvalarr.push(_this.getproporall(_this.SensityProportionList));
      //获取皱纹的总和
      allvalarr.push(_this.getproporall(_this.WrinkleProportionList));
      //获取痤疮的总和
      allvalarr.push(_this.getproporall(_this.AcneProportionList));
      //获取毛孔的总和
      allvalarr.push(_this.getproporall(_this.PoreProportionList));
      //获取色沉的总和
      allvalarr.push(_this.getproporall(_this.SpotProportionList));
      //获取黑头的总和
      allvalarr.push(_this.getproporall(_this.getBlackheadProportionList));
      console.log("我草", allvalarr);
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: function (params) {
                let bfb = [];
                var values = allvalarr;
                var total = values.reduce(function (a, b) {
                  return parseInt(a) + parseInt(b);
                }, 0);

                values.map(function (value) {
                  bfb.push(((value / total) * 100).toFixed(2));
                });
                var arr = ["敏感", "皱纹", "座疮", "毛孔", "色沉", "黑头"];
                return (
                  arr[params.dataIndex] + ":" + bfb[params.dataIndex] + "%"
                );
              },
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: allvalarr, //_this.SpotAnalyzeList,
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = [
                    "#C12420",
                    "#D25D51",
                    "#DE8573",
                    "#E7A58E",
                    "#EDBAA0",
                    "#F3CFB2",
                  ];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initBarChart21() {
      // 通过 $ref 进行挂载
      let myChart = this.$echarts.init(this.$refs.barChart21);
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            label: {
              show: true,
              fontSize: 8,
              position: "inside",
              formatter: function (params) {
                let bfb = [];
                var values = [
                  2518090, 2240055, 3473013, 1815189, 2030304, 1756922,
                ];
                var total = values.reduce(function (a, b) {
                  return a + b;
                }, 0);

                values.map(function (value) {
                  bfb.push(((value / total) * 100).toFixed(2));
                });
                var arr = ["敏感", "皱纹", "座疮", "毛孔", "色沉", "黑头"];
                return (
                  arr[params.dataIndex] + ":" + bfb[params.dataIndex] + "%"
                );
              },
            },
            labelLine: {
              show: false,
              length: "0.001%",
            },
            data: [2518090, 2240055, 3473013, 1815189, 2030304, 1756922],
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = [
                    "#C12420",
                    "#D25D51",
                    "#DE8573",
                    "#E7A58E",
                    "#EDBAA0",
                    "#F3CFB2",
                  ];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //图表自适应
    //循环获取症状无、轻度、中度、重度的总合
    getproporall(arrval) {
      var total = arrval.reduce(function (sum, value) {
        return sum + value;
      }, 0);
      return total;
    },
    //机构信息
    async infofn() {
      let _this = this;
      const res = await info();
      _this.info = res.data[0];
      _this.account = _this.info["orgAccount"];
      console.log("1、机构信息---->", _this.info);
      _this.selectHasCheckDevicefn();
      _this.getMoremeDevicefn();
    },
    //今年门店概括接口
    async getMrData() {
      let _this = this;
      const res = await selectShopInfo();
      _this.orgData = res.data;
      console.log("2、今年门店概况---->", _this.orgData);

      _this.getClientCountfn();
      _this.getCasegroupCountfn();
      _this.getCasegroupAvgCountfn();
      _this.selectDeviceBaseInfofu();
    },
    //设备基本情况
    async selectDeviceBaseInfofu() {
      let _this = this;
      const res = await selectDeviceBaseInfo();
      if (10 - res.data.yearDeviceTypeInfoList.length < 10) {
        console.log("asdf", 10 - res.data.yearDeviceTypeInfoList.length);
        let numint = 10 - res.data.yearDeviceTypeInfoList.length;
        for (let i = 0; i < numint; i++) {
          res.data.yearDeviceTypeInfoList.push({
            deviceTypeName: "-",
            deviceNum: "-",
            startUpNum: "-",
            avgStartUpNum: "-",
            avgStartUpNumByCounty: "-",
          });
        }
      }
      _this.selectDeviceBaseInfo = res.data;
      console.log("3、设备基本情况---->", _this.selectDeviceBaseInfo);
      _this.selectDeviceStartUpfn();
    },
    //设备启动次数
    async selectDeviceStartUpfn() {
      let _this = this;
      const res = await selectDeviceStartUp();
      _this.selectDeviceStartUp = res.data;
      this.initBarChart();
      console.log("4、设备启动次数---->", _this.selectDeviceStartUp);
      this.selectDeviceStartUpTopDescfn();
    },
    //设备开机排行榜，红榜
    async selectDeviceStartUpTopDescfn() {
      let _this = this;
      const res = await selectDeviceStartUpTopDesc();
      if (10 - res.data.length < 10) {
        let numint = 10 - res.data.length;
        for (let i = 0; i < numint; i++) {
          res.data.push({
            deviceid: "-",
            deviceTypeName: "-",
            shopName: "-",
            startUpNum: "-",
          });
        }
      }
      _this.selectDeviceStartUpTopDesc = res.data;
      console.log(
        "6、设备开机排行榜，红榜---->",
        _this.selectDeviceStartUpTopDesc
      );
      this.selectDeviceStartUpTopAscfn();
    },
    //设备开机排行榜，黑榜
    async selectDeviceStartUpTopAscfn() {
      let _this = this;
      const res = await selectDeviceStartUpTopAsc();
      if (10 - res.data.length < 10) {
        let numint = 10 - res.data.length;
        for (let i = 0; i < numint; i++) {
          res.data.push({
            deviceid: "-",
            deviceTypeName: "-",
            shopName: "-",
            startUpNum: "-",
          });
        }
      }
      _this.selectDeviceStartUpTopAsc = res.data;
      console.log(
        "7、设备开机排行榜，黑榜---->",
        _this.selectDeviceStartUpTopAsc
      );
      this.selectShopStartUpTopfn();
    },
    //门店开机情况
    async selectShopStartUpTopfn() {
      let _this = this;
      const res = await selectShopStartUpTop();
      if (res.data.length === 0) {
        res.data = [
          {
            deviceTypeName: "-",
            deviceStartUpTopDTOList: [
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
            ],
          },
          {
            deviceTypeName: "-",
            deviceStartUpTopDTOList: [
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
            ],
          },
          {
            deviceTypeName: "-",
            deviceStartUpTopDTOList: [
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
              {
                shopName: "-",
                startUpNum: "-",
              },
            ],
          },
        ];
      } else {
        for (let i = 0; i < res.data.length; i++) {
          if (10 - res.data[i].deviceStartUpTopDTOList.length < 10) {
            let num = 10 - res.data[i].deviceStartUpTopDTOList.length;
            for (let j = 0; j < num; j++) {
              res.data[i].deviceStartUpTopDTOList.push({
                shopName: "-",
                startUpNum: "-",
              });
            }
          }
        }
      }
      _this.ShopStartUpTop = res.data;
      _this.selectEmpOperateTopfn();
    },
    //员工操作排行
    async selectEmpOperateTopfn() {
      let _this = this;
      const res = await selectEmpOperateTop();
      if (10 - res.data.length < 10) {
        let numint = 10 - res.data.length;
        for (let i = 0; i < numint; i++) {
          res.data.push({
            empName: "-",
            shopName: "-",
            startUpNum: "-",
          });
        }
      }
      _this.selectEmpOperateTop = res.data;
      console.log("8、员工操作排行---->", _this.selectEmpOperateTop);
      _this.selectConUseInfofn();
    },
    //设备耗材使用排行
    async selectConUseInfofn() {
      let _this = this;
      const res = await selectConUseInfo();
      for (let i = 0; i < res.data.length; i++) {

        let arr=res.data[i].deviceStartUpTopDTOList;
        res.data[i].deviceStartUpTopDTOList=arr.slice(0,6);
        if (6 - res.data[i].deviceStartUpTopDTOList.length < 6) {
          let num = 6 - res.data[i].deviceStartUpTopDTOList.length;
          for (let j = 0; j < num; j++) {
            res.data[i].deviceStartUpTopDTOList.push({
              consumableTypeName: "-",
              startUpNum: "-",
            });
          }
        }
      }
      _this.selectConUseInfo = res.data;
      console.log("9、设备耗材使用排行---->", _this.selectConUseInfo);
      _this.selectShopConUseTopfn();
    },
    //门店耗材使用排行
    async selectShopConUseTopfn() {
      let _this = this;
      const res = await selectShopConUseTop();
      console.log("10、设备耗材使用排行---->", res.data);
      for (let i = 0; i < res.data.length; i++) {
        if (10 - res.data[i].length < 10) {
          let num = 10 - res.data[i].length;
          for (let j = 0; j < num; j++) {
            res.data[i].push({
              shopName: "-",
              consumableName: "-",
            });
          }
        }
      }
      _this.selectShopConUseTop = res.data;
      console.log("10、设备耗材使用排行---->", _this.selectShopConUseTop);
    },
    //查询机构是否有光电设备
    async selectHasCheckDevicefn() {
      let _this = this;
      const res = await selectHasCheckDevice();
      //_this.selectHasCheckDevice = res.data
      if (res.data == 0) {
        _this.isGdDevice = 1;
      } else {
        _this.isGdDevice = 0;
      }
      console.log("11、查询机构是否有光电设备---->", res.data);
    },

    //查询机构下是否有检测类设备
    async getMoremeDevicefn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getMoremeDevice(json);

      //res.data=0
      _this.getMrData();
      if (res.data == 1) {
        _this.isMoremeDevice = 1;
      } else {
        _this.isMoremeDevice = 0;
      }

      console.log("12、查询机构下是否有检测类设备---->", res);
    },
    //皮肤检测概况（顾客累计数量）
    async getClientCountfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getClientCount(json);
      _this.ClientCount = res.data;
      console.log("13、皮肤检测概况（顾客累计数量）---->", res);
    },
    //皮肤检测概况（案例数量）
    async getCasegroupCountfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getCasegroupCount(json);
      _this.CasegroupCount = res.data;
      console.log("14、皮肤检测概况（案例数量）---->", res);
    },
    //皮肤检测概况（平均案例数量）
    async getCasegroupAvgCountfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getCasegroupAvgCount(json);
      _this.CasegroupAvgCount = res.data;
      console.log("15、皮肤检测概况（平均案例数量）---->", res);
      if (_this.isMoremeDevice == 1) {
        _this.getClientCountThisYearfn();
      }
    },
    //检测顾客情况
    async getClientCountThisYearfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res1 = await getClientCountThisYear(json);
      const res2 = await getClientCountMonthList(json);
      _this.clientCounts.clientCountThisYear = res1.data;
      _this.clientCounts.clientCountMonthList = res2.data;
      this.initBarChart1();
      console.log("16、检测顾客情况---->", res1 + "|" + res2);
      this.getClientProvinceCountsfn();
    },
    //顾客分布情况
    async getClientProvinceCountsfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res1 = await getClientProvinceCount(json);
      const res2 = await getClientProvinceCountList(json);
      _this.clientProvinceCount = res1.data;
      _this.clientProvinceCountList = res2.data;
      setTimeout(function () {
        _this.initBarChart2();
      }, 500);

      console.log("17、顾客分布情况---->");
      console.log(res2);
      this.getCasegroupCounts();
    },
    //检测案例情况
    async getCasegroupCounts() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res1 = await getCasegroupCountThisYear(json);
      const res2 = await getCasegroupCountMonthList(json);
      _this.CasegroupCountThisYear = res1.data;
      _this.CasegroupCountMonthLis = res2.data;
      this.initBarChart3();
      console.log("18、检测案例情况---->", res1 + "|" + res2);
      this.getClientCasegroupCountListfn();
    },
    //检测案例情况
    async getClientCasegroupCountListfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getClientCasegroupCountList(json);
      _this.ClientCasegroupCountList = JSON.parse(res.data);
      console.log("19、检测案例情况---->", res);
      this.getClientCasegroupCountsfn();
    },
    //检测案例情况(范围)
    async getClientCasegroupCountsfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res1 = await getCasegroupTotalOneMemberList(json);
      res1.data = JSON.parse(res1.data);
      if (5 - res1.data.length <= 5) {
        let num = 5 - res1.data.length;
        for (let i = 0; i < num; i++) {
          res1.data.push({
            name: "-",
            total: "-",
          });
        }
      }
      const res2 = await getCasegroupTotalTwoMemberList(json);
      res2.data = JSON.parse(res2.data);
      if (5 - res2.data.length <= 5) {
        let num = 5 - res2.data.length;
        for (let i = 0; i < num; i++) {
          res2.data.push({
            name: "-",
            total: "-",
          });
        }
      }
      const res3 = await getCasegroupTotalThreeMemberList(json);
      res3.data = JSON.parse(res3.data);
      if (5 - res3.data.length <= 5) {
        let num = 5 - res3.data.length;
        console.log("res3", num);
        for (let i = 0; i < num; i++) {
          res3.data.push({
            name: "-",
            total: "-",
          });
        }
      }
      const res4 = await getCasegroupTotalFourMemberList(json);
      res4.data = JSON.parse(res4.data);
      if (5 - res4.data.length <= 5) {
        let num = 5 - res4.data.length;
        for (let i = 0; i < num; i++) {
          res4.data.push({
            name: "-",
            total: "-",
          });
        }
      }
      _this.CasegroupTotalOneMemberList = res1.data;
      _this.CasegroupTotalTwoMemberList = res2.data;
      _this.CasegroupTotalThreeMemberList = res3.data;
      _this.CasegroupTotalFourMemberList = res4.data;
      console.log(
        "19、检测案例情况(范围)---->",
        res1 + "|" + res2 + "|" + res3 + "|" + res4
      );
      this.getClientCasegroupCountTopTenListfn();
    },
    //案例组数排行
    async getClientCasegroupCountTopTenListfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getClientCasegroupCountTopTenList(json);
      res.data = JSON.parse(res.data);
      if (10 - res.data.length <= 10) {
        let num = 10 - res.data.length;
        for (let i = 0; i < num; i++) {
          res.data.push({
            cname: "-",
            dmname: "-",
            cgcount: "-",
          });
        }
      }
      _this.ClientCasegroupCountTopTenList = res.data;
      console.log("20、检测案例情况---->", res);
      _this.getMemberCasegroupCounthongfn();
    },
    //门店案例排行（红榜）
    async getMemberCasegroupCounthongfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res1 = await getMemberCasegroupCountTopFiveThisYearList(json);
      res1.data = JSON.parse(res1.data);
      if (5 - res1.data.length <= 5) {
        let num = 5 - res1.data.length;
        for (let i = 0; i < num; i++) {
          res1.data.push({
            dmname: "-",
            total: "-",
          });
        }
      }
      const res2 = await getTopFiveCasegroupCountList(json);
      _this.MemberCasegroupCountTopFiveThisYearList = res1.data;
      _this.TopFiveCasegroupCountList = JSON.parse(res2.data);
      console.log("21、门店案例排行（红榜）---->", res1);
      _this.getMemberCasegroupCountheifn();
      this.initBarChart4();
    },
    //门店案例排行（黑榜）
    async getMemberCasegroupCountheifn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res1 = await getMemberCasegroupCountBottomFiveThisYearList(json);
      res1.data = JSON.parse(res1.data);
      if (5 - res1.data.length <= 5) {
        let num = 5 - res1.data.length;
        for (let i = 0; i < num; i++) {
          res1.data.push({
            dmname: "-",
            total: "-",
          });
        }
      }
      const res2 = await getBottomFiveCasegroupCountList(json);
      _this.MemberCasegroupCountBottomFiveThisYearList = res1.data;
      _this.BottomFiveCasegroupCountList = JSON.parse(res2.data);
      _this.initBarChart5();

      console.log("22、门店案例排行（黑榜）---->", res1);
      _this.getSensityProportionListfn();
    },

    //症状分布（敏感）
    async getSensityProportionListfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getSensityProportionList(json);
      _this.SensityProportionList = JSON.parse(res.data);
      this.initBarChart6();
      this.initBarChart7();
      console.log("23、症状分布（敏感））---->", res);
      this.getWrinkleProportionListfn();
    },
    //症状分布（皱纹）
    async getWrinkleProportionListfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getWrinkleProportionList(json);
      _this.WrinkleProportionList = JSON.parse(res.data);
      this.initBarChart8();
      this.initBarChart9();
      console.log("24、症状分布（皱纹））---->", res);
      this.getAcneProportionListfn();
    },
    //症状分布（痤疮）
    async getAcneProportionListfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getAcneProportionList(json);
      _this.AcneProportionList = JSON.parse(res.data);
      this.initBarChart10();
      this.initBarChart11();
      console.log("25、症状分布（痤疮））---->", res);
      this.getPoreProportionListfn();
    },
    //症状分布（毛孔）
    async getPoreProportionListfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getPoreProportionList(json);
      _this.PoreProportionList = JSON.parse(res.data);
      this.initBarChart12();
      this.initBarChart13();
      console.log("26、症状分布（毛孔））---->", res);
      this.getSpotProportionListfn();
    },
    //症状分布（色沉）
    async getSpotProportionListfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getSpotProportionList(json);
      _this.SpotProportionList = JSON.parse(res.data);
      this.initBarChart14();
      this.initBarChart15();
      console.log("27、症状分布（色沉））---->", res);
      this.getBlackheadProportionListfn();
    },
    //症状分布（黑头）
    async getBlackheadProportionListfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getBlackheadProportionList(json);
      _this.getBlackheadProportionList = JSON.parse(res.data);
      this.initBarChart16();
      this.initBarChart17();
      console.log("28、症状分布（黑头））---->", res);
      this.getSpotAnalyzeListfn();
    },
    //症状分布（色斑）
    async getSpotAnalyzeListfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getSpotAnalyzeList(json);
      _this.SpotAnalyzeList = JSON.parse(res.data);
      this.initBarChart18();
      this.initBarChart19();
      setTimeout(function () {
        _this.initBarChart20();
        _this.initBarChart21();
      }, 500);
      console.log("29、症状分布（色斑））---->", res);
      this.getMarketAnalyzefn();
    },
    //症状市场分析
    async getMarketAnalyzefn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getMarketAnalyze(json);
      _this.MarketAnalyze = JSON.parse(res.data);
      console.log("30、症状市场分析---->", res);
      this.getGoodsListfn();
    },
    //产品曝光度统计
    async getGoodsListfn() {
      let _this = this;
      let json = {
        account: _this.account,
      };
      const res = await getGoodsList(json);
      res.data = JSON.parse(res.data);
      if (10 - res.data.length <= 10) {
        let num = 10 - res.data.length;
        for (let i = 0; i < num; i++) {
          res.data.push({
            goodsName: "-",
            total: "-",
          });
        }
      }
      _this.GoodsList = res.data;
      console.log("30、产品曝光度统计---->", res);
    },

    //判断横竖屏
    renderResize() {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      let _this = this;
      console.log("!!", width, height);
      if (width > height) {
        console.log("横屏");
        this.$router.go(0);
        _this.tipsshow = 0;
      } else {
        this.tipsshow = 1;
        setTimeout(function () {
          _this.tipsshow = 0;
        }, 2500);
        this.$router.go(0);
        console.log("竖屏");
      }
    },
    renderResize2() {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      let _this = this;
      console.log("!!", width, height);
      if (width > height) {
        console.log("横屏");
        _this.tipsshow = 0;
      } else {
        this.tipsshow = 1;
        setTimeout(function () {
          _this.tipsshow = 0;
        }, 2500);
        console.log("竖屏");
      }
    },
    //监听页面当前高度
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop = document.documentElement.scrollTop;
      console.log("??", scrollTop);
      if (scrollTop > this.divHeight * 4) {
        console.log("第二批次");
      } else if (scrollTop > this.divHeight * 8) {
        console.log("第三批次");
      }
    },
  },
  created() {
    this.getInit();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.renderResize, false);
  },
};
</script>
<style src="../css/annualReport.css"></style>
