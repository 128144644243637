import request from "@/utils/xcxRequestdb";
const song = "https://ds.skin.imoreme.com/";//"http://192.168.1.164:8089/";
const luo = 'https://djm.imoreme.com/'; //"http://47.112.182.226:8081"; //luo='http://testdjm.imoreme.com/';
/*
 * 物联网接口，罗聪
 * */
//机构信息
export function info() {
  request.defaults.baseURL = luo;
  return request({
    url: "djmaftersales/yearEndReport/org/info",
    method: "get",
  });
}
//今年门店概况
export function selectShopInfo() {
  request.defaults.baseURL = luo;
  return request({
    url: "djmaftersales/yearEndReport/selectShopInfo",
    method: "get",
  });
}
//设备基本情况
export function selectDeviceBaseInfo() {
  request.defaults.baseURL = luo;
  return request({
    url: "djmaftersales/yearEndReport/selectDeviceBaseInfo",
    method: "get",
  });
}
//设备启动次数
export function selectDeviceStartUp() {
  request.defaults.baseURL = luo;
  return request({
    url: "djmaftersales/yearEndReport/selectDeviceStartUp",
    method: "get",
  });
}

//设备开机排行榜，红榜
export function selectDeviceStartUpTopDesc() {
  request.defaults.baseURL = luo;
  return request({
    url: "djmaftersales/yearEndReport/selectDeviceStartUpTopDesc",
    method: "get",
  });
}
//设备开机排行榜，黑榜
export function selectDeviceStartUpTopAsc() {
  request.defaults.baseURL = luo;
  return request({
    url: "djmaftersales/yearEndReport/selectDeviceStartUpTopAsc",
    method: "get",
  });
}
//门店开机情况
export function selectShopStartUpTop() {
  request.defaults.baseURL = luo;
  return request({
    url: "djmaftersales/yearEndReport/selectShopStartUpTop",
    method: "get",
  });
}

//员工操作排行
export function selectEmpOperateTop() {
  request.defaults.baseURL = luo;
  return request({
    url: "djmaftersales/yearEndReport/selectEmpOperateTop",
    method: "get",
  });
}
//设备耗材使用排行
export function selectConUseInfo() {
  request.defaults.baseURL = luo;
  return request({
    url: "djmaftersales/yearEndReport/selectConUseInfo",
    method: "get",
  });
}
//门店耗材使用排行
export function selectShopConUseTop() {
  request.defaults.baseURL = luo;
  return request({
    url: "djmaftersales/yearEndReport/selectShopConUseTop",
    method: "get",
  });
}
//查询机构是否有光电设备
export function selectHasCheckDevice() {
  request.defaults.baseURL = luo;
  return request({
    url: "djmaftersales/yearEndReport/selectHasCheckDevice",
    method: "get",
  });
}

/*
 * 检测仪接口，老宋
 * */
//查询机构下是否有检测类设备
export function getMoremeDevice(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getMoremeDevice",
    method: "get",
    params,
  });
}
//皮肤检测概况（顾客数量）
export function getClientCount(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getClientCount",
    method: "get",
    params,
  });
}
//皮肤检测概况（案例数量）
export function getCasegroupCount(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getCasegroupCount",
    method: "get",
    params,
  });
}
//皮肤检测概况（平均案例数量）
export function getCasegroupAvgCount(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getCasegroupAvgCount",
    method: "get",
    params,
  });
}

//检测顾客情况（今年增加顾客数）
export function getClientCountThisYear(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getClientCountThisYear",
    method: "get",
    params,
  });
}
//检测顾客情况（每月新增顾客）
export function getClientCountMonthList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getClientCountMonthList",
    method: "get",
    params,
  });
}
//顾客分布情况（顾客分布省份数量）
export function getClientProvinceCount(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getClientProvinceCount",
    method: "get",
    params,
  });
}
//顾客分布情况（顾客分布的省份数据）
export function getClientProvinceCountList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getClientProvinceCountList",
    method: "get",
    params,
  });
}
//检测案例情况（今年新增案例）
export function getCasegroupCountThisYear(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getCasegroupCountThisYear",
    method: "get",
    params,
  });
}
//检测案例情况（每月新增案例列表）
export function getCasegroupCountMonthList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getCasegroupCountMonthList",
    method: "get",
    params,
  });
}
//门店检测顾客情况(案例数量超过一定数量的门店数量)
export function getClientCasegroupCountList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getClientCasegroupCountList",
    method: "get",
    params,
  });
}
//门店检测顾客情况(100)
export function getCasegroupTotalOneMemberList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getCasegroupTotalOneMemberList",
    method: "get",
    params,
  });
}
//门店检测顾客情况(30-100)
export function getCasegroupTotalTwoMemberList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getCasegroupTotalTwoMemberList",
    method: "get",
    params,
  });
}
//门店检测顾客情况(10-30)
export function getCasegroupTotalThreeMemberList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getCasegroupTotalThreeMemberList",
    method: "get",
    params,
  });
}
//门店检测顾客情况(10以下)
export function getCasegroupTotalFourMemberList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getCasegroupTotalFourMemberList",
    method: "get",
    params,
  });
}
//案例组数排行
export function getClientCasegroupCountTopTenList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getClientCasegroupCountTopTenList",
    method: "get",
    params,
  });
}
//门店案例排行（红榜列表）
export function getMemberCasegroupCountTopFiveThisYearList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getMemberCasegroupCountTopFiveThisYearList",
    method: "get",
    params,
  });
}
//门店案例排行（红榜图表）
export function getTopFiveCasegroupCountList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getTopFiveCasegroupCountList",
    method: "get",
    params,
  });
}

//门店案例排行（黑榜列表）
export function getMemberCasegroupCountBottomFiveThisYearList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getMemberCasegroupCountBottomFiveThisYearList",
    method: "get",
    params,
  });
}
//门店案例排行（黑榜图表）
export function getBottomFiveCasegroupCountList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getBottomFiveCasegroupCountList",
    method: "get",
    params,
  });
}

//症状分布（敏感）
export function getSensityProportionList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getSensityProportionList",
    method: "get",
    params,
  });
}
//症状分布（皱纹）
export function getWrinkleProportionList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getWrinkleProportionList",
    method: "get",
    params,
  });
}
//症状分布（痤疮）
export function getAcneProportionList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getAcneProportionList",
    method: "get",
    params,
  });
}
//症状分布（毛孔）
export function getPoreProportionList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getPoreProportionList",
    method: "get",
    params,
  });
}

//症状分布（色沉）
export function getSpotProportionList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getSpotProportionList",
    method: "get",
    params,
  });
}
//症状分布（黑头）
export function getBlackheadProportionList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getBlackheadProportionList",
    method: "get",
    params,
  });
}
//症状分布（色斑）
export function getSpotAnalyzeList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getSpotAnalyzeList",
    method: "get",
    params,
  });
}

//症状市场分析
export function getMarketAnalyze(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getMarketAnalyze",
    method: "get",
    params,
  });
}
//产品曝光度统计
export function getGoodsList(params) {
  request.defaults.baseURL = song;
  return request({
    url: "dataReporter/getGoodsList",
    method: "get",
    params,
  });
}
