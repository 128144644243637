import axios from "axios";
const service = axios.create({
  //baseURL:'http://192.168.1.164:8082/',//宋有余
  //baseURL:'http://192.168.1.43:8180/',//宋有余本地
  //baseURL: 'http://192.168.26.98:8082/',
  //baseURL: "http://192.168.1.168:8080/", //罗聪本地
  //baseURL: "https://testdjm.imoreme.com/",//测试服务器
  baseURL: "/api",//本地测试用
  timeout: 100000, // request timeout
});
const params = new URLSearchParams(location.search);
// request interceptor
service.interceptors.request.use(
  (config) => {
      //token
      //let nowtoken="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHQiOjE3MDMzOTM2MTA1ODcsInVpZCI6Im81MTVlNUVvME8wQ2pDN042b1JxcmJqT0x3NUUiLCJpZCI6NDM0LCJzcmMiOiJBUFAiLCJpYXQiOjE3MDMzODg0MjY1ODd9.KPYTAGFBlpS8yC7QPG_UEMKrL6DgBVDXOXvqqSAWP70";
      //config.headers["token"] =nowtoken;
      config.headers["token"] =params.get("token");
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);
// response interceptor
service.interceptors.response.use(
  (response) => {
    if (response.data instanceof Blob) {
      return response;
    }
    const res = response.data;
    //console.log('公共接口返回的数据--->',res);
    // if the custom msg.returnCode is not 20000, it is judged as an error.
    if (res.code !== 0&&res.code !== 200) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (
        res.code === 400 ||
        res.code === 401 ||
        res.code === 402 ||
        res.code === 403
      ) {
      }
      return Promise.reject(new Error(res.msg || "Error")).catch((res) => {
        console.log('错误',res);
      });
    } else {
      //这里是 必须code=200  才给你返回的res
      return res;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
