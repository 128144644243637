import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueWechatTitle from "vue-wechat-title";
import "lib-flexible";
Vue.use(VueWechatTitle);
Vue.config.productionTip = false;
// 引入wow动画
import wow from "wowjs";
import "wowjs/css/libs/animate.css";
import 'normalize.css'
Vue.prototype.$wow = wow;

// 引入 echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

// 计算并设置根元素的字体大小
function setRem() {
  const rem = (document.documentElement.offsetWidth / 375) * 10;
  document.documentElement.style.fontSize = rem + "px";
}

//引入自定义过滤器
import { numberToCurrencyNo,timedjs } from "./filter/filter";
Vue.filter("numberToCurrencyNo", numberToCurrencyNo);
Vue.filter("timedjs", timedjs);

// 初始化时调用一次
setRem();

// 监听窗口大小变化时重新计算
window.addEventListener("resize", setRem);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
